import { Checkbox, Divider, Grid, IconButton, Stack, Typography, TextField, InputAdornment } from "@mui/material";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import { useMemo, useState, useEffect } from "react";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import {
  EditProjectParams,
  IsProjectDateAvailableResponse,
  PhotographyProject,
  WorkflowType,
} from "../api/types";
import { closeSnackbar, useSnackbar } from "notistack";
import { useEditProjectMutation, useIsProjectDateAvailable } from "../api/projects";
import CloseIcon from "@mui/icons-material/Close";
import { formatMoney, zonedDate } from "../util/common";
import { userCurrencyAtom } from "../layouts/Main";
import { DateTime } from "luxon";
import axios, { AxiosError } from "axios";
import { useFormikContext } from "formik";
import { FormValues } from "../components/MynkForm/MynkFormConditional";
import { atom, useAtom, useAtomValue } from "jotai";
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const isProjectAvailableDataAtom = atom<
  undefined | IsProjectDateAvailableResponse
>(undefined);

function DateController() {
  const formik = useFormikContext<FormValues>();
  const values = formik?.values;

  const [dateToCheck, setDateToCheck] = useState<undefined | string>(undefined);
  const { data, isLoading } = useIsProjectDateAvailable({ date: dateToCheck });
  const [dataAtom, setDataAtom] = useAtom(isProjectAvailableDataAtom);

  useEffect(() => {
    setDataAtom(data);
  }, [data]);

  useEffect(() => {
    if (formik && values && values?.["startDate"]) {
      const zStartDate = zonedDate(values?.["startDate"]);
      setDateToCheck(zStartDate.toISO());
    }
  }, [values?.["startDate"]]);

  return <></>;
}


export interface EditProjectDialogProps {
  open: boolean;
  onClose: () => void;
  project?: PhotographyProject | null;
}

export default function EditProjectDialog(props: EditProjectDialogProps) {
  const [isAllDay, setIsAllDay] = useState(props.project?.is_all_day);
  const [price, setPrice] = useState<string>("");
  const userCurrency = useAtomValue(userCurrencyAtom);

  const [isProjectDateAvailableData, setIsProjectDateAvailableData] = useAtom(
    isProjectAvailableDataAtom
  );

  useEffect(() => {
    if (props.open) {
      setIsProjectDateAvailableData(undefined);
    }
  }, [props.open]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
  };
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const editProject = useEditProjectMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, EditProjectParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    }
  });
  const handleSubmit = async (values: any) => {
    if (!props.project?.uuid) return;
    const zStartDate = values?.["startDate"] ? zonedDate(values["startDate"]) : null;
    const zEndDate = values?.["endDate"] ? zonedDate(values["endDate"]) : null;

    const startDate =  zStartDate ? DateTime.fromObject({
      year: zStartDate.year,
      month: zStartDate.month,
      day: zStartDate.day,
    }) : null;

    const endDate = zEndDate? DateTime.fromObject({
      year: zEndDate.year,
      month: zEndDate.month,
      day: zEndDate.day,
    }) : null;

    if((zonedDate(values?.["startDate"]) >= zonedDate(values?.["endDate"])))
    { 
      setErrorMsg("End date before the start date")
      return
    }
    const params: EditProjectParams = {
      uuid: props.project.uuid,
      fields: 
      {
        name: values["projectName"] ? values["projectName"].trim() : "",
        client_uuid: values["clientId"] ?? "",
        description: values["description"] ?? "",
        date: startDate,
        end_date: endDate ? endDate : undefined,
        location: values["projectLocation"]
          ? values["projectLocation"].trim()
          : "",
        price: price ? parseFloat(price) : 0,
      },
    };

    await editProject.mutateAsync(params);
    enqueueSnackbar("Project edited successfully", {
      variant: "success",
      action: (key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ),
      style: {
        borderRadius: "0.7rem",
      },
    });
    props.onClose();
  };
  const initialValues = useMemo(() => {
    if (!props.project) return {};
    setIsAllDay(props.project.is_all_day);
    return {
      projectName: props.project.name,
      clientId: props.project.client_uuid,
      description: props.project.description,
      date: props.project.date ? zonedDate(props.project.date) : undefined,
      endDate: props.project.end_date ? zonedDate(props.project.end_date) : undefined,
      projectLocation: props.project.location,
      price: props.project.price,
      leadSource: props.project.lead_source,
    };
  }, [props.project]);

  console.log("initialValues", initialValues);
  const currencyCode = useAtomValue(userCurrencyAtom);

  if (!props.project) {
    return <></>;
  }

  return (
    <MynkFormDialog
      open={props.open}
      onClose={() => {
        setPrice(!props.project || props.project.price === 0 ? "" : props.project.price.toString());
        props.onClose();
      }}
      title="Edit project"
      action="Apply changes"
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={editProject.isPending}
    >
      <DateController />
      <Grid container spacing={"1.1rem"} sx={{ width: "28.5714rem" }}>
        <Grid item xs={12}>
          <Divider sx={{ mb: 0.5 }} />
        </Grid>

        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="projectName"
              label="Project's name"
              placeholder="Enter project name"
              autoComplete={false}
              default={initialValues?.projectName ?? ""}
            />
          </MynkFormEditable>
        </Grid>

        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.CONTACT_SEARCH}
              name="clientId"
              label="Client"
              placeholder="Enter client's name"
              default={initialValues?.clientId ?? ""}
              workflowType={WorkflowType.PHOTOGRAPHY}
            />
          </MynkFormEditable>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mb: 0.5 }} />
        </Grid>

        <Stack
          alignItems="center"
          gap={1.5}
          sx={{
            justifyContent: "center",
            alignContent: "center",
            cursor: "pointer",
            marginLeft: "0.5rem",
            marginTop: "1rem",
          }}
        >
          {isProjectDateAvailableData?.is_available == false  && (
              <Stack>
                <Typography color={"red"}>You have {isProjectDateAvailableData?.projects?.length} project(s) in this range:</Typography>
              </Stack>
          )}

            
        </Stack>
        <Grid item xs={13}>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="startDate"
                  label="Date"
                  default={initialValues.date ?? null}
                />
        </Grid>
        <Grid item xs={13}>
                <MynkFormControl
                  kind={MynkFormControlKind.DATE_SELECT}
                  name="endDate"
                  label="End Date"
                  default={initialValues.endDate ?? null}
                />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 0.5 }} />
        </Grid>

        <Grid item xs={12}>
          <MynkFormEditable>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="projectLocation"
              label="Location"
              placeholder="Enter location"
              autoComplete={false}
              default={initialValues?.projectLocation ?? " "}
            />
          </MynkFormEditable>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 0.5 }} />
        </Grid>
        <Grid item xs={12}>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" fontSize={'1.22rem'}>Price</Typography>
            <TextField
              fullWidth
              variant="standard"
              value={price}
              placeholder={"0.00"}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {formatMoney(undefined, userCurrency)}
                  </InputAdornment>
                ),
                sx: {
                  height: '3.6rem',
                  mt: '-0.9rem',
                },
              }}
              onChange={(e) => {
                const inputValue = e.target.value;

                const numberRegex = /^(\d+)?(\.\d{0,2})?$/;
                if (!numberRegex.test(inputValue)) {
                  return;
                }

                setPrice(inputValue)
              }}
              sx={{
                width: '4rem',
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MynkFormEditable longText>
            <MynkFormControl
              kind={MynkFormControlKind.TEXT}
              name="description"
              label="Description"
              placeholder="Enter project's description"
              autoComplete={false}
              multiLine={true}
              default={initialValues?.description ?? ""}
              height={100}
            />
          </MynkFormEditable>
        </Grid>
        {errorMsg? (
          <Stack
          marginTop={"1rem"}
          marginLeft={"1.1rem"}>
            <Typography color={"red"}>{errorMsg}</Typography>
          </Stack>
        ):(null)}
      </Grid>
    </MynkFormDialog>
  );
}
