import React, { useEffect, useState } from "react";
import { MynkFormControl, MynkFormDialog } from "../components/MynkForm";
import {
  Checkbox,
  Grid,
  IconButton,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  ALL_PROJECT_STAGE_OPTIONS,
  PRIORITY_OPTIONS,
} from "../components/ChipSelect/commonOptions";
import { useNavigate } from "react-router-dom";
import { MynkFormControlKind } from "../components/MynkForm/controlTypes";
import { closeSnackbar, useSnackbar } from "notistack";
import Zoom from '@mui/material/Zoom';
import { PATHS, WorkflowPath, makePath } from "../paths";
import {
  useAddPhotographyProjectMutation,
  useIsProjectDateAvailable,
} from "../api/projects";
import {
  AddProjectParams,
  IsProjectDateAvailableResponse,
  Priority,
  ProjectStage,
  WorkflowType,
} from "../api/types";
import { DateSelectQuickOption } from "../components/MynkForm/controls/DateSelect";
import { DateTime } from "luxon";
import MynkFormEditable from "../components/MynkForm/MynkFormEditable";
import { useFormikContext } from "formik";
import { zonedDate } from "../util/common";
import { FormValues } from "../components/MynkForm/MynkFormConditional";
import axios, { AxiosError } from "axios";
import { atom, useAtom, useAtomValue } from "jotai";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";

const isProjectAvailableDataAtom = atom<
  undefined | IsProjectDateAvailableResponse
>(undefined);
const hasDateAtom = atom(false);
const rangeValidAtom = atom(true)
interface DateControllerProps {
  setStartDateTime?: (newDatetime: DateTime) => void;
  setEndDateTimeOnlyTime?: (newVal: boolean) => void;
}

function DateController(props: DateControllerProps) {
  const formik = useFormikContext<FormValues>();
  const values = formik?.values;

  const [dateToCheck, setDateToCheck] = useState<undefined | string>(undefined);  
  const [endDateToCheck, setEndDateToCheck] = useState<undefined | string>(undefined);

  const { data, isLoading } = useIsProjectDateAvailable({ date: dateToCheck,end_date: endDateToCheck });
  const [dataAtom, setDataAtom] = useAtom(isProjectAvailableDataAtom);
  const [rangeValid, setRangeValid] = useAtom(rangeValidAtom);

  const [hasDate, setHasDate] = useAtom(hasDateAtom);
  useEffect(() => {
    if (formik && values) {
      setHasDate(Boolean(values?.["startDate"]));
    }  
  }, [values?.["startDate"],values?.["endDate"]]);

  useEffect(() => {
    setDataAtom(data);
  }, [data]);

  

  useEffect(() => {
    if (formik && values && values?.["startDate"]) {
      const zEndDate = null
      if(values?.["endDate"])
      {
        const zEndDate = zonedDate(values?.["endDate"]);
        setEndDateToCheck(zEndDate.toISO());
      }
      const zStartDate = zonedDate(values?.["startDate"]);
      setDateToCheck(zStartDate.toISO());
      
      
    }
  }, [values?.["startDate"],values?.["endDate"]]);

  return <></>;
}

export interface AddPhotographyProjectDialogProps {
  open: boolean;
  onClose: () => void;
  contactId?: string;
}

const DATE_QUICK_OPTIONS: DateSelectQuickOption[] = [
  { label: "Today", deltaMin: 0, deltaMax: 1 },
  { label: "Tommorrow", deltaMin: 1, deltaMax: 2 },
];
export function AddPhotographyProjectDialog(
  props: AddPhotographyProjectDialogProps
) {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [isProjectDateAvailableData, setIsProjectDateAvailableData] = useAtom(
    isProjectAvailableDataAtom
  );
  const [rangeValid, setRangeValid] = useAtom(rangeValidAtom);
  const [isOnlyEnd,setIsOnlyDate] = useState(false)

  useEffect(() => {
    if (props.open) {
      setIsProjectDateAvailableData(undefined);
    }
  }, [props.open]);

 

  const addProject = useAddPhotographyProjectMutation({
    onError: (error: unknown) => {
      if (error instanceof Error && "response" in error) {
        const axiosError = error as AxiosError<unknown, AddProjectParams>;
        const detail = (axiosError.response?.data as { detail?: string })
          ?.detail;
        setErrorMsg(detail ?? "");
      }
    },
  });
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    if (values?.["endDate"] && values?.["startDate"]) {
      if(zonedDate(values?.["startDate"]) >= zonedDate(values?.["endDate"])) 
      {
        setErrorMsg("End date can't be before the start date")
        return
      }
      
    }
    let startDate, endDate;
    if(!rangeValid)
      return
    if(values?.["endDate"] && !values?.["startDate"])
    {
      setErrorMsg("Need to choose a Date for End Date")
      return
    }
    try {
      const zStartDate = values["startDate"] ? zonedDate(values["startDate"]) : null;
      const zEndDate = values["endDate"] ? zonedDate(values["endDate"]) : null;
      const zStartTime = values["startTime"] ? zonedDate(values["startTime"]) : null;
      const zEndTime = values["endTime"] ? zonedDate(values["endTime"]) : null;

      if (zStartDate) {
        startDate = DateTime.fromObject({
          year: zStartDate.year,
          month: zStartDate.month,
          day: zStartDate.day,
          hour: zStartTime ? zStartTime.hour : 0,
          minute: zStartTime ? zStartTime.minute : 0,
          second: zStartTime ? zStartTime.second : 0,
        });
      }
      if (zEndDate) {
        endDate = DateTime.fromObject({
          year: zEndDate.year,
          month: zEndDate.month,
          day: zEndDate.day,
        });
      }
      
      
    } catch (e) {
      setErrorMsg("Some fields are empty or invalid");
    }

    const result = await addProject.mutateAsync({
      name: values["projectName"]?.trim() || '',
      contact_id: values["contactId"],
      priority: Priority.MEDIUM,
      stage: ProjectStage.LEAD,
      date: startDate || undefined,
      end_date: endDate || undefined,
      is_all_day: true,
    });

    if (result?.uuid) {
      navigate(
        makePath(
          WorkflowPath.PHOTOGRAPHY,
          PATHS.viewProject(result.uuid).general
        )
      );
      props.onClose();
    }
  };

  const hasDate = useAtomValue(hasDateAtom);

  return (
    <MynkFormDialog
      open={props.open}
      onClose={props.onClose}
      title="New project"
      action="Create project"
      onSubmit={handleSubmit}
      loading={addProject.isPending}
    >
      <DateController />
      <Grid container spacing={"0.7rem"} sx={{ width: "32.1429rem" }}>
        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.TEXT}
            name="projectName"
            label="Project's name"
            placeholder="Enter project's name"
            autoComplete={false}
            default=""
          />
        </Grid>

        <Grid item xs={12}>
          <MynkFormControl
            kind={MynkFormControlKind.CONTACT_SEARCH}
            name="contactId"
            label="Client"
            placeholder="Enter client's name"
            default={props.contactId ?? ""}
            workflowType={WorkflowType.PHOTOGRAPHY}
          />
        </Grid>

        <Stack
          alignItems="center"
          gap={1.5}
          sx={{
            justifyContent: "center",
            alignContent: "center",
            cursor: "pointer",
            marginLeft: "0.5rem",
            marginTop: "1rem",
          }}
        >
           
          {isProjectDateAvailableData?.is_available == false && hasDate && (
          <Stack>
            <Typography color={"red"}>You have {isProjectDateAvailableData?.projects?.length} project(s) in this range:</Typography>
          </Stack>
          )}
        </Stack>
        <Grid item xs={13}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="startDate"
              label="Date"
              default={null}
            />
        </Grid>
        <Grid item xs={13}>
            <MynkFormControl
              kind={MynkFormControlKind.DATE_SELECT}
              name="endDate"
              label="End Date (Optional)"
              default={null}
            />
        </Grid>
        {errorMsg? (
          <Stack
          marginTop={"1rem"}
          marginLeft={"0.55rem"}>
            <Typography color={"red"}>{errorMsg}</Typography>
          </Stack>
        ):(null)}
        
      </Grid>
    </MynkFormDialog>
  );
}
