import {
  Priority,
  PhotographyProject,
  ProjectStage,
} from "../../../../api/types";
import React, { ReactNode, useMemo, useState } from "react";
import { Box, Divider, Grid, Stack, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { formatMoney, zonedDate } from "../../../../util/common";
import {
  useSetProjectPriorityMutation,
  useSetProjectStageMutation,
} from "../../../../api/projects";
import { ChipSelect } from "../../../../components/ChipSelect";
import { ALL_PROJECT_STAGE_OPTIONS } from "../../../../components/ChipSelect/commonOptions";
import PriorityChip from "../../../../components/PriorityChip";
import ElevatorDialog from "../../../../components/ElevatorDialog";
import { PATHS, WorkflowPath, makePath } from "../../../../paths";
import { useAtomValue } from "jotai";
import { userCurrencyAtom } from "../../../../layouts/Main";
import { DateTime, Settings } from "luxon";

interface TruncatedDescriptionProps {
  description: string | null;
  maxLength?: number;
  sx?: SxProps;
}

/**
 * TruncatedDescription is a typescript-react function that takes in a description string and a maximum length as inputs.
 * It returns a truncated version of the description if it exceeds the maximum length, with three dots appended at the end.
 *
 * @param {TruncatedDescriptionProps} props - The props object containing the description and maxLength.
 * @returns {JSX.Element} - The truncated description component.
 */
export function TruncatedDescription(props: TruncatedDescriptionProps) {
  // Set default length:
  if (props.maxLength == null) {
    props.maxLength = 200;
  }

  // If the description is not overflow the max length - show as usual:
  if (!props.description || props.description.length <= props.maxLength) {
    return (
      <Typography
        display="block"
        variant="body2"
        sx={{ whiteSpace: "pre-line", color: "#404040b2" }}
      >
        {props.description || "N/A"}
      </Typography>
    );
  }

  // If overflow - slice the content and add 3 dots:
  const truncatedContent = `${props.description.slice(0, props.maxLength)}...`;
  return (
    <Typography variant="body2" sx={{ color: "#404040b2" }}>
      {truncatedContent}
    </Typography>
  );
}

interface FullDescriptionPopUpProps {
  open: boolean;
  fullDescription: string;
  onClose: () => void;
}

// Pop-Up window to show the full project description:
export function FullDescriptionPopUp(props: FullDescriptionPopUpProps) {
  return (
    <ElevatorDialog open={props.open} onClose={props.onClose}>
      <Stack sx={{ width: "30rem", py: "0.7rem" }} spacing="1.1rem">
        <Typography variant="h2" fontFamily="Helvetica Neue Bold">Project's description</Typography>
        <Box sx={{ maxHeight: "20rem", overflowY: "auto", pr: "0.4rem"}}>
          <Typography sx={{ whiteSpace: "pre-line", color: "#404040b2" }}>
            {props.fullDescription || "Unavailable"}
          </Typography>
        </Box>
      </Stack>
    </ElevatorDialog>
  );
}

export interface UpperCardProps {
  project: PhotographyProject;
}

export function UpperCard(props: UpperCardProps) {

  const date = props.project.date ? useMemo(() => zonedDate(props.project.date), [props.project.date]) : null;

  const endDate = props.project.end_date ? useMemo(() => props.project.end_date ? zonedDate(props.project.end_date) : undefined, [props.project.end_date]) : null;

  return (
    <>
      {/* Upper card title */}
      <Typography variant="h4" fontFamily="Helvetica Neue">
        Project details
      </Typography>

      {/* Client row (with link to the view client) */}
      <Stack sx={{ px: 2.3, pt: 2.9, pb: 1.2 }} spacing={2.55}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Client:</Typography>
          <Typography
            variant="body2"
            sx={{ color: "#75B3FF", textDecoration: "none" }}
            component={Link}
            to={makePath(
              WorkflowPath.PHOTOGRAPHY,
              PATHS.viewContact(props.project.client_uuid).general
            )}
          >
            {props.project.client_name || "N/A"}
          </Typography>
        </Stack>

        <Divider />

        {/* Location row */}
        <Stack justifyContent="space-between" alignItems="baseline" direction={'row'}>
          <Typography variant="body2">Location:</Typography>
          <Typography
            display="block"
            variant="body2"
            sx={{ whiteSpace: "pre-line", color: "#404040b2", mt: 1 }}
          >
            {props.project.location || "N/A"}
          </Typography>
        </Stack>

        <Divider />

        {/* Due date row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Date:</Typography>
          {!date ? (
            <Typography
              display="block"
              variant="body2"
              sx={{ whiteSpace: "pre-line", color: "#404040b2", mt: 1 }}
            >
              N/A
            </Typography>
          ) : (
          <Typography variant="body2">
            <b style={{ color: "#404040" }}>{date.toFormat("EEEE")}</b>,{" "}
            <span style={{ color: "#404040b2" }}>
              {date.toFormat("LLL dd, yyyy")}
            </span>
          </Typography>
          )}
        </Stack>

          {!endDate ? (null) : (
            <><Divider />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
            <Typography variant="body2">End Date:</Typography>
            <Typography variant="body2">
              <b style={{ color: "#404040" }}>{endDate.toFormat("EEEE")}</b>,{" "}
              <span style={{ color: "#404040b2" }}>
              {endDate.toFormat("LLL dd, yyyy") || "N/A"}
              </span>
            </Typography>
            </Stack></>
          )}

        
      </Stack>
    </>
  );
}

export interface LowerCardProps {
  project: PhotographyProject;
}

export function LowerCard(props: LowerCardProps) {
  const currencyCode = useAtomValue(userCurrencyAtom);
  const [openedViewFullDescription, setOpenedViewFullDescription] =
    useState(false);

  const setProjectStage = useSetProjectStageMutation();
  const handleChangeStage = (value: string) => {
    setProjectStage.mutate({
      uuid: props.project.uuid,
      stage: value as ProjectStage,
    });
  };

  const setProjectPriority = useSetProjectPriorityMutation();
  const handleChangePriority = (value: string) => {
    setProjectPriority.mutate({
      uuid: props.project.uuid,
      priority: value as Priority,
    });
  };

  const handleViewFullDescription = () => {
    setOpenedViewFullDescription(true);
  };

  return (
    <>
      <FullDescriptionPopUp
        open={openedViewFullDescription}
        fullDescription={props.project.description ?? ""}
        onClose={() => setOpenedViewFullDescription(false)}
      />

      {/* No title for the lower card */}
      <Stack sx={{ px: 1, pt: 0.3, pb: 1 }} spacing={2.55}>
        {/* Stage chosing row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Stage:</Typography>
          <ChipSelect
            options={ALL_PROJECT_STAGE_OPTIONS}
            value={props.project.stage}
            onChange={handleChangeStage}
            sx={{ bgcolor: "white", width: "8rem", height: "2.5rem" }}
          />
        </Stack>

        <Divider />

        {/* Priority chosing row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Priority:</Typography>
          <PriorityChip
            value={props.project.priority}
            onChange={handleChangePriority}
            sx={{ width: "7.7rem", height: "2.5rem" }}
          />
        </Stack>

        <Divider />

    <Stack spacing="0.4rem">
        {/* Project's description (shorten, full will be displayed when clicking on "View" link/button) */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="body2">Project's description:</Typography>
          {props.project.description &&
          
          <div onClick={handleViewFullDescription}>
            <Typography
              variant="body2"
              sx={{
                color: "#75B3FF",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              View
            </Typography>
          </div>
          }
        </Stack>

        <TruncatedDescription // Responsible for shorten the description.
          description={props.project.description ?? "N/A"}
          maxLength={140}
        />
      </Stack>
        <Divider />

        {/* Price row */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
          sx={{ pt: 1 }}
        >
          <Typography variant="body2">Price:</Typography>
          <Typography fontWeight="bold" fontSize="1.45rem" color="black">
            {formatMoney(props.project.price, currencyCode) || "N/A"}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
