import React, { useState, useEffect } from "react";
import { Box, IconButton, Stack, SxProps, Button, Popover, FormGroup, FormControlLabel, Checkbox, Link, Typography } from "@mui/material";
import { FilterOption, SearchOption, SortOption } from "../../api/types";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { ChipSelectOption } from "../ChipSelect";
import SearchBox from "./Search";
import FilterBox from "./Filter";
import SortBox from "./Sort";
import { SimpleTableColumn } from "../SimpleTable";
import filterIcon from "../../assets/icons/filter-icon.svg";
import chipSelectArrowIcon from "../../assets/icons/chipselect-arrow-icon.svg";
import { SimpleTableCardProps } from "../SimpleTable";
import { CSVLink } from 'react-csv';
import { useGetProjectMutation } from "../../api/projects";
import { useGetClientMutation } from "../../api/clients";
import DownloadDialog from "../../dialogs/DownloadDialog";






interface ClearSearchFilterSortProps {
  searchState: [
    SearchOption,
    React.Dispatch<React.SetStateAction<SearchOption>>
  ];
  filterState: [
    FilterOption,
    React.Dispatch<React.SetStateAction<FilterOption>>
  ];
  sortState: [SortOption, React.Dispatch<React.SetStateAction<SortOption>>];
  size?: number;
}

export function ClearSearchFilterSort(props: ClearSearchFilterSortProps) {
  const [search, setSearch] = props.searchState;
  const [filter, setFilter] = props.filterState;
  const [sort, setSort] = props.sortState;

  const handleClear = () => {
    if (setSearch && setFilter && setSort) {
      setSearch({ query: "" });
      setFilter({ by: "", query: "" });
      setSort({ by: "", descending: false });
    }
  };

  return (
    <Box
      sx={{
        height: props.size ? `${props.size / 14}rem` : "2.4286rem",
        width: props.size ? `${props.size / 14}rem` : "2.4286rem",
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "#ecf0f5",
        borderRadius: "50%",
      }}
    >
      <IconButton
        sx={{
          bgcolor: "white",
          width: props.size
            ? `${(props.size - 7) / 14 / 1.07}rem`
            : "2.1429rem",
          height: props.size
            ? `${(props.size - 7) / 14 / 1.07}rem`
            : "2.1429rem",
          boxShadow: "1px 1px 4px rgba(174, 155, 230, 0.15)",
        }}
        onClick={handleClear}
      >
        <RestartAltIcon
          sx={{
            color: "#B4B6B9",
            width: props.size ? `${props.size / 2 / 14}rem` : "1.2143rem",
            height: props.size ? `${props.size / 2 / 14}rem` : "1.2143rem",
          }}
        />
      </IconButton>
    </Box>
  );
}

interface ColumnSelectorProps {
  allColumns: { key: string; label: string }[];
  selectedColumns: string[];
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

function ColumnSelector({ allColumns, selectedColumns, setSelectedColumns }: ColumnSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggleColumn = (key: string) => {
    setSelectedColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      onClick={handleClick}
      direction="row"
      sx={{
        borderRadius: "15rem",
        cursor: "pointer",
        alignItems: "center",
        bgcolor: "#ecf0f5",
        height: "2.85rem",
      }}
    >
      <Box
        component="img"
        src={filterIcon}
        sx={{
          width: "2.35rem",
          height: "2.35rem",
          ml: 0.67,
          cursor: "pointer",
          mt: 0.2,
        }}
      />

      <Typography
        sx={{
          color: "#888b8f",
          fontSize: "1.25rem",
          paddingLeft: "0.5rem",
          paddingRight: "1.5rem",
          fontFamily: "Helvetica Neue",
          fontWeight: 600,
        }}
      >
        Filter
      </Typography>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {}}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <FormGroup sx={{ p: 2 }}>
          {allColumns.map((col) => (
            <FormControlLabel
              key={col.key}
              control={
                <Checkbox
                  checked={selectedColumns.includes(col.key)}
                  onChange={() => handleToggleColumn(col.key)}
                />
              }
              label={col.label}
            />
          ))}
        </FormGroup>
      </Popover>

      <Box
        component="img"
        src={chipSelectArrowIcon}
        sx={{
          width: "0.7rem",
          height: "0.7rem",
          mr: "1rem",
        }}
      />
    </Stack>
  );
};

interface SearchFilterSortProps extends SimpleTableCardProps {
  searchState: [
    SearchOption,
    React.Dispatch<React.SetStateAction<SearchOption>>
  ];
  filterState: [
    FilterOption,
    React.Dispatch<React.SetStateAction<FilterOption>>
  ];
  sortState: [SortOption, React.Dispatch<React.SetStateAction<SortOption>>];
  height?: number;
  searchWidth?: number | string;
  isSearchStretched?: boolean;
  spacing?: number;
  sx?: SxProps;
}

export default function SearchFilterSort(props: SearchFilterSortProps) {
  const [csvData, setCsvData] = useState<any[]>([]);
  const getClientMutation = useGetClientMutation({});
  const getProjectMutation = useGetProjectMutation({});
  const [open, setOpen] = useState(false);

  // Function to fetch and replace UUIDs with names
  const fetchNames = async () => {
    const updatedData = await Promise.all(
      props.data.map(async (item) => {
        let projectName = item.project_uuid;
        let clientName = item.client_uuid;

        // Fetch project name using project_uuid
        if (item.project) {
          const response = await getProjectMutation.mutateAsync({ project_uuid: item.project });
          if (response.project) {
            projectName = response.project.name;
          }
        }

        if (item.client) {
          const response = await getClientMutation.mutateAsync({ uuid: item.client });
          if (response.client) {
            clientName = response.client.full_name;
          }
        }
        
        const filteredItem = Object.keys(item).reduce((acc, key) => {
          if (key.toLowerCase().includes('uuid') || key.toLowerCase() === 'client' || key.toLowerCase() === 'project') {
            return acc;
          }
          return {
            ...acc,
            [key]: item[key] === 'none' ? '' : item[key],
          };
        }, {});

        return {
          ...filteredItem,
          project_name: projectName,
          client_name: clientName,
        };
      })
    );
    setCsvData(updatedData);
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      fetchNames();
    }
  }, [props.data]);

  // Automatically generate headers from the object keys, excluding uuid fields
  const headers = Object.keys(csvData[0] || {}).map((key) => ({
    label: key.replace(/_/g, ' ').toUpperCase(),
    key: key,
  }));

  // Prepare the CSV report data
  const csvReport = {
    filename: 'Project_Data.csv',
    headers: headers,
    data: csvData,
  };

  return (
    <Stack
      direction="row"
      sx={{ ...props.sx }}
      spacing={props.spacing ?? 0}
      justifyContent="center"
      alignItems="center"
    >
      <DownloadDialog open={open} onClose={() => setOpen(false)} csvReport={csvReport} />

      {!(props.searchState[0].query == "" &&
        props.filterState[0].by == "" &&
        props.filterState[0].query == "" &&
        props.sortState[0].by == "") && (
          <ClearSearchFilterSort
            searchState={props.searchState}
            filterState={props.filterState}
            sortState={props.sortState}
            size={props.height}
          />
        )
      }

      {props.downloadToCsv && props.data && (
        <Button
          onClick={() => setOpen(true)}
          sx={{
            color: '#75b3ff',
            fontSize: '0.9rem',
            fontFamily: 'Helvetica Neue',
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#4a90e2',
            },
          }}
        >
          Download CSV
        </Button>
      )}

      <ColumnSelector
        allColumns={props.allColumnsWithoutInfo?.map((col) => ({
          key: col.key,
          label: col.label,
        })) ?? []}
        selectedColumns={props.selectedColumns ?? []}
        setSelectedColumns={props.setSelectedColumns ?? (() => {})}
      />

      {/* <FilterBox
        filterState={props.filterState}
        filterOptions={props.filterOptions}
        height={props.height}
      /> */}

      <SearchBox
        searchState={props.searchState}
        height={props.height}
        isStretched={props.isSearchStretched}
        width={props.searchWidth}
      />

      <SortBox
        sortState={props.sortState}
        sortOptions={props.sortOptions}
        height={props.height}
      />
    </Stack>
  );
}
